<template>
<div class="pa-5">

  <h3 class="wsDARKER mb-5">{{ $t('mailer.templates.blocks.title') }}</h3>

  <ws-button-group
      @input="items = []; initPage()"
      v-model="navigation"
      :items="navigationSelect"
      class="mb-5"
  />

  <div v-if="navigation === 'saved_templates' && !this.items.length && !this.LOADING">

    <div class="d-flex justify-center">
      <v-icon size="80" class="mt-10" :color="wsDARKLIGHT">mdi-bookmark-outline</v-icon>
    </div>

    <h4 class="wsDARKER text-center mt-4">{{ $t('mailer.templates.blocks.no_saved_items') }}</h4>
    <h5 class="wsACCENT font-weight-regular text-center mt-4">
      {{ $t('mailer.templates.blocks.no_saved_items_info_1') }}
      <v-icon :color="wsACCENT" size="18">mdi-bookmark-outline</v-icon>
      {{ $t('mailer.templates.blocks.no_saved_items_info_2') }}
    </h5>

  </div>
  <v-hover
      #default="{hover}"
      v-for="block in items" :key="block"
  >
    <v-sheet
        @click="selectBlock(block)"
        class="pa-2 wsRoundedSemi d-flex align-center mb-4 overflow-hidden pointer"
        :style="`border: 2px solid ${hover ? wsACCENT : wsLIGHTACCENT}`"
        style="position: relative"
    >
      <v-btn
          v-if="hover && navigation === 'saved_templates'"
          @click.stop="openDeleteTemplateBlock(block)"
          style="position: absolute; top : 8px; right : 8px; z-index: 9"
          :color="wsATTENTION"
          min-width="34"
          width="34"
          elevation="0"
          class="wsRoundedSemi"
      >
        <v-icon :color="wsWHITETEXT">mdi-delete-outline</v-icon>
      </v-btn>
      <email-block-preview
          :uuid="block"
      />

    </v-sheet>
  </v-hover>


  <ws-dialog
      v-model="displayDeleteDialog"
      :title="$t('mailer.templates.blocks.delete')"
      display-confirm-delete
      close-delete
      @delete="deleteTemplateBlock"
  >

  </ws-dialog>

</div>
</template>

<script>
import {mapActions} from "vuex";
import emailBlockPreview from "@modules/finemailer/components/emailConstructor/viewer/emailBlockPreview.vue";
export default {
  name: "emailBlockTemplatesSelector",
  components : { emailBlockPreview },
  data() {
    return {
      items : [],
      navigation : 'westudy',
      displayDeleteDialog : false,
      selectedTemplate : null
    }
  },
  computed : {
    navigationSelect() {
      return [
        { text : 'WeStudy' , value : 'westudy' },
        { text : this.$t('mailer.saved_templates') , value : 'saved_templates' }
      ]
    }
  },
  methods : {
    ...mapActions('finemailer' , [
      'GET_NEWSLETTER_BLOCKS_TEMPLATES',
      'SET_BLOCK_TEMPLATE',
      'DELETE_NEWSLETTER_DESIGN_BLOCK'
    ]),

    openDeleteTemplateBlock(block) {
      this.selectedTemplate = block
      this.displayDeleteDialog = true
    },
    async deleteTemplateBlock() {
      let result = await this.DELETE_NEWSLETTER_DESIGN_BLOCK(this.selectedTemplate)
      if ( !result ) {
        return this.ERROR()
      }
      this.items = this.items.filter(el => el !== this.selectedTemplate)
      this.displayDeleteDialog = false

    },

    async selectBlock(blockUuid) {
      if (this.LOADING) {
        return
      }
      let data = {
        template_id: blockUuid,
        uuid: this.$store.state.finemailer.selectedBlock.uuid
      }
      await this.SET_BLOCK_TEMPLATE(data)
      this.$store.state.finemailer.blockTemplateSelectTrigger++

    },
    async initPage() {
      this.items = (await this.GET_NEWSLETTER_BLOCKS_TEMPLATES(this.navigation))
    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>